import React, { useMemo, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  groupsSearchOpenSearchSrc111Evid521,
  groupsSearchSearchRequestSrc111Evid522,
  searchTabClickSrc111Evid524,
} from '@wix/bi-logger-groups-data/v2';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Divider } from 'wui/Divider';
import { TextField } from 'wui/TextField';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';
import { Drawer, type IDrawerProps } from 'wui/Drawer';
import { Tabs } from 'wui/Tabs';
import { Show } from 'wui/Show';

import classes from './SearchDrawer.scss';

interface ISearchDrawerProps extends Omit<IDrawerProps, 'children'> {
  title: string;
  placeholder: string;
  groupId?: string;
  origin: string;
  tabs: { id: string; title: string }[];
  children: (
    query: string | undefined,
    containerRef: React.RefObject<HTMLDivElement>,
    activeTab: string,
    handleReset: () => void,
  ) => React.ReactNode;
}

export function SearchDrawer({
  title,
  placeholder,
  groupId,
  origin,
  isOpen,
  onClose,
  children,
  tabs,
  ...rest
}: ISearchDrawerProps) {
  const bi = useBi();
  const { isRTL } = useEnvironment();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState<string>();

  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleQueryChange = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
      bi.report(
        groupsSearchSearchRequestSrc111Evid522({
          groupId,
          origin,
          inputText: event.target.value,
          tabName: activeTab,
        }),
      );
    }, 300);
  }, [groupId, origin, activeTab]);

  useEffect(() => {
    if (!isOpen) {
      setQuery(undefined);
    } else {
      bi.report(
        groupsSearchOpenSearchSrc111Evid521({
          origin,
          groupId,
        }),
      );
    }
  }, [isOpen]);

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={false}
      aria-labelledby="search-drawer-title"
      {...rest}
    >
      <DialogContainer scrollable>
        <DialogTitle id="search-drawer-title" title={t(title)} />
        <Divider bw />
        <DialogContent scrollable={false}>
          <TextField
            autoComplete="off"
            theme="line"
            value={query}
            withClearButton
            prefix={<SearchIcon />}
            onClear={handleReset}
            onChange={handleQueryChange}
            placeholder={t(placeholder)}
          />
          <Show if={tabs.length > 1}>
            <Tabs
              alignment={isRTL ? 'right' : 'left'}
              items={tabs}
              onChange={handleTabOnChange}
              value={activeTab}
              variant="fullWidth"
            />
          </Show>
        </DialogContent>
        <DialogContent
          ref={containerRef}
          scrollable
          disableSideGutters
          className={classes.searchResults}
          aria-live="polite"
        >
          {children(query, containerRef, activeTab, handleReset)}
        </DialogContent>
      </DialogContainer>
    </Drawer>
  );

  function handleReset() {
    setQuery(undefined);
  }

  function handleTabOnChange(activeTab: string) {
    bi.report(
      searchTabClickSrc111Evid524({
        tab_name: activeTab,
        origin,
        group_id: groupId,
      }),
    );

    setActiveTab(activeTab);
  }
}

SearchDrawer.displayName = 'SearchDrawer';
